import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { CommonService } from '../../shared-service/common.service';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';


var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT'
  })
};
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class SideBarService {
  getTourPlanURL = 'getTourplan';

  constructor(private readonly http:HttpClient , private readonly appService:CommonService) { }

  gettourPlan(): Observable<any> {
    const url = this.appService.regisBaseUrl + this.getTourPlanURL;
    httpOptions.headers = httpOptions.headers.set('authorization', localStorage.getItem('sidebarToken'));
    console.log("httpoptions",httpOptions);
    return this.http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  generateToken(): Observable<any> {
    let body = {
      "clientId":"3b101113-fab6-47d1-ade1-46517a3e59a8"
    }
    const url = this.appService.tourSaveTokenUrl
    console.log("body",body);
    return this.http.post(url,body,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }



  // ===================================== Exception Handler ===========================================
  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMsg = `Backend returned code ${error.status}`;
    }
    // return an observable with a user-facing error message
    return throwError(errorMsg);
  }
}
