import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery'

@Component({
  selector: 'app-admin-sidenav',
  templateUrl: './admin-sidenav.component.html',
  styleUrls: ['./admin-sidenav.component.scss']
})
export class AdminSidenavComponent implements OnInit {
  accessDetails: any;
  admin = false;
  constructor(private readonly router: Router) {
    router.events.subscribe((s) => {
      if (this.router.url == '/user/manage-plant' || this.router.url == '/user/tour-schedule' ) {
        $('#configureTours').addClass('show')
      }
      else{
        $('#configureTours').removeClass('show')
      }
    })
  }

  ngOnInit() {
    this.accessDetails = JSON.parse(localStorage.getItem('TOTOU_accessDetail'));
    // console.log(this.accessDetails.access_detail.response.length)
    if (this.accessDetails != null && this.accessDetails.access_detail.response.length != 0 && this.accessDetails != undefined) {
      console.log(this.accessDetails.access_detail.response[0])
      if (this.accessDetails.access_detail.response[0].user_role_nm == 'ADMIN') {
        this.admin = true;
      }
      else {
        this.admin = false;
      }
    }
    
  }

}
