import { BrowserModule } from '@angular/platform-browser';
import { NgModule, } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProgressBarModule } from "angular-progress-bar";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TourLayoutComponent } from './view/tour-layout/tour-layout.component';
import { AppMaterialModule } from './app-material/app-material.module';
import { ComponentsModule } from './components/components.module';
import { GroupSizeComponent } from './group-size/group-size.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminLayoutComponent } from './view/admin-layout/admin-layout.component';
import {MatSelectModule} from '@angular/material/select';
import { HttpClientModule } from '@angular/common/http';
import { NgxTrumbowygModule } from 'ngx-trumbowyg';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,AdminLayoutComponent,TourLayoutComponent, 
    
  ],
  imports: [
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    BrowserModule,
    MatSelectModule,
    ComponentsModule,
    BrowserAnimationsModule,
    ProgressBarModule,
    BsDatepickerModule,
    AppMaterialModule,
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxTrumbowygModule.withConfig({
      lang: 'hu',
      svgPath: '/assets/icons.svg',
      removeformatPasted: true,
      autogrow: true,
      btns: [
        ['strong', 'em', 'del'],
        ['underline'],
        ['formatting'],
        ['undo', 'redo'],
        ['superscript', 'subscript'],
        ['link'],
        ['insertImage'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat']
        // ['fullscreen']
      ]
    })
  ],
  providers: [GroupSizeComponent, SideBarComponent,DeviceDetectorService],
  bootstrap: [AppComponent],
})
export class AppModule {
}
