import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HomeService } from '../home/home.service';
import { resolve } from 'url';
import { JSONPBackend } from '@angular/http';

import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';


var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT'
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
}


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  editvar: boolean = false;
  tourObj: any = {};
  location: any = '';
  groupSize: any = 1;
  date: any;
  time: any;
  myInfo: any = {};
  groupInfo: any;
  grpsizeResponse: any;
  grpInfoResponse: any;
  guestList: any;
  grouptype: any;
  groupName: any;
  groupDetails: any;
  uniquekey: any;
  reservationDetails: any = [];
  adminBaseUrl = environment.adminBaseUrl
  createReservationBaseUrl = environment.createReservationBaseUrl
  reportBaseUrl = environment.reportBaseUrl
  regisBaseUrl = environment.regisBaseUrl
  cancelEmailBaseUrl = environment.cancelEmailBaseUrl
  managePlantBaseUrl = environment.managePlantBaseUrl
  saveTokenBaseUrl = environment.saveTokenBaseUrl
  tourSaveTokenUrl = environment.tourSaveTokenUrl
  exportDownloadUrl = environment.exportDownloadUrl

  saveTokenURL = "saveToken";
  getAccessDetailsURL = "getUserAccess?user_email=";
  deviceInfo: any;
  ipAddress: Observable<any>;
  userIpAddress: any
  userLocationDetails: any
  startTime: number;
  endTime: any;
  totalTimeSpentOnPage: any
  deviceTypeUsed: string;
  insertAuditDataUrl: any = 'insertAuditData';
  updateAuditDataUrl: any = 'updateAuditData';
  getScreenInfoDataUrl: any = 'getScreenInfoData';
  tokenForAuditDetails: any;
  auditDataId: any;
  preScreenInfoId: any = null;
  preScreenInfoIdBoolean: Boolean = false;
  screenInfoIdArray: any = [];


  constructor(private readonly http: HttpClient, private deviceService: DeviceDetectorService,
    private router: Router, private titleService: Title
  ) { }



  // Set Title
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  // Uppercase First
  Ucase(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  getScreenInfi() {
    return new Promise(async resolve => {
      (await this.getScreenInfoDetails()).subscribe((datas: any) => {
        console.log("datas", datas);

        if (datas) {
          console.log('data fro screen info id array', datas.Screen_info_data.response)
          this.screenInfoIdArray = datas.Screen_info_data.response
          resolve(true)
        }
      })
    })
    // this.appService.getScreenInfoDetails()

  }
  async getScreenInfoDetails() {
    let a = await this.getToken()
    if (a) {
      const url = this.regisBaseUrl + this.getScreenInfoDataUrl;
      let body = {}
      httpOptions.headers = httpOptions.headers.set('authorization', localStorage.getItem('homeToken'));
      console.log("httpoptions in getScreenInfoDetails", httpOptions);
      return this.http.get(url, httpOptions)
        .map((response: any) => response).catch(this.handleError);
    }
  }

  getToken() {
    return new Promise(resolve => {
      this.generateToken().subscribe(async (response: any) => {
        if (response) {
          console.log("token response", response.access_token);
          let homeToken = response.access_token
          localStorage.setItem('homeToken', homeToken)
          console.log("hometoken", localStorage.getItem('homeToken'));
          resolve(true)
        }
      })
    })

  }

  auditDetailFunction(toy_tour_screen_info_name, linkName, linkHeader, ipAddress) {
    return new Promise(resolve => {
      let toyTourScreenInfoId = null
      console.log('inside audit detail fuction', 'ip', ipAddress, 'linkName', linkName, 'urlLoc', linkHeader)
      if (ipAddress) {
        // fetch("https://extreme-ip-lookup.com/json/").then( res => res.json())
        // .then(async (res: any) => {
        //     console.log("Country: ", res.country);
        this.getIPAddress().subscribe(async (res: any) => {
          this.userLocationDetails = res

          if (this.userLocationDetails) {
            console.log('userLocationDetails', this.userLocationDetails)

            this.deviceInfo = this.deviceService.getDeviceInfo();
            console.log('deviceInfo', this.deviceInfo)
            const isMobile = this.deviceService.isMobile();
            const isTablet = this.deviceService.isTablet();
            const isDesktopDevice = this.deviceService.isDesktop();
            if (!this.preScreenInfoIdBoolean) {  // for external navigation
              this.preScreenInfoId = null
            }

            if (isMobile) {
              this.deviceTypeUsed = 'Mobile'
            }
            if (isTablet) {
              this.deviceTypeUsed = 'Tablet'

            }
            if (isDesktopDevice) {
              this.deviceTypeUsed = 'Desktop/Laptop'

            }
            let screenFlag = await this.getScreenInfi()
            if (screenFlag) {
              console.log('screenFlag', screenFlag)
              for (let i = 0; i < this.screenInfoIdArray.length; i++) {
                if (toy_tour_screen_info_name == this.screenInfoIdArray[i].screen_nm) {
                  toyTourScreenInfoId = this.screenInfoIdArray[i].toy_tour_screen_info_id
                }
              }
              console.log('preScreenInfoId', this.preScreenInfoId, 'toyTourScreenInfoId', toyTourScreenInfoId)


              let body = {
                'device_type': this.deviceTypeUsed,
                'browser': this.deviceInfo.browser,
                'browser_version': this.deviceInfo.browser_version,
                'ip_address': ipAddress,
                'city': this.userLocationDetails.city,
                'state': this.userLocationDetails.region,
                'country': this.userLocationDetails.country,
                'toy_tour_screen_info_id': toyTourScreenInfoId,
                'toy_tour_link_name': linkName,
                'toy_tour_link_header': linkHeader,
                'toy_tour_prev_screen_info_id': this.preScreenInfoId



              }

              console.log('body before fun call in audit', body)

              let a = await this.getTokenForAuditDetails()

              if (a) {
                this.sendAuditDetailsToDb(body).subscribe((response: any) => {
                  console.log('response of audit', response)
                  console.log('response of audit',
                    response.insert_audit_data.response[0].toy_tour_audit_data_id)
                  this.auditDataId = response.insert_audit_data.response[0].toy_tour_audit_data_id
                  this.preScreenInfoId = toyTourScreenInfoId;
                  this.preScreenInfoIdBoolean = false;
                  console.log('preScreenInfoId in end', this.preScreenInfoId, 'toyTourScreenInfoId in end', toyTourScreenInfoId)
                  resolve(this.auditDataId)


                })
              }
            }


          }
        });
      }
    })


  }

  getTokenForAuditDetails() {
    return new Promise(resolve => {
      this.generateToken().subscribe((response: any) => {
        if (response) {
          // console.log("token response", response.access_token);
          this.tokenForAuditDetails = response.access_token;
          resolve(true)
        }
      })
    })
  }

  sendAuditDetailsToDb(body): Observable<any> {
    console.log('body in sendAuditDetailsToDb', body, 'tokenForAuditDetails', this.tokenForAuditDetails)

    const url = this.regisBaseUrl + this.insertAuditDataUrl;
    httpOptions.headers = httpOptions.headers.set('authorization', this.tokenForAuditDetails);
    console.log("httpoptions", httpOptions);
    return this.http.post(url, body, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  async updateAuditDetailsInDb(auditDataId) {
    let a = await this.getTokenForAuditDetails()
    if (a) {
      let body = {
        toy_tour_audit_data_id: auditDataId
      }
      console.log('audit data body id', body)

      const url = this.regisBaseUrl + this.updateAuditDataUrl;
      httpOptions.headers = httpOptions.headers.set('authorization', this.tokenForAuditDetails);
      console.log("httpoptions", httpOptions);
      return this.http.post(url, body, httpOptions)
        .map((response: any) => response).catch(this.handleError);
    }


  }
  generateToken(): Observable<any> {
    let body = {
      "clientId": "3b101113-fab6-47d1-ade1-46517a3e59a8"
    }
    const url = this.tourSaveTokenUrl
    console.log("body", body);
    return this.http.post(url, body, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  userLoggingOut() {
    // this.userLeavingTime = new Date().toLocaleString()
    this.endTime = Date.now();
    console.log('this.endTime', this.endTime)

    this.totalTimeSpentOnPage = this.endTime - this.startTime;
    console.log('totalTimeSpentOnPage', this.totalTimeSpentOnPage / 1000)
  }
  getIPAddress(): Observable<any> {
    // return this.http.get("https://jsonip.com")
    return this.http.get("https://extreme-ip-lookup.com/json/")
      .map((response: any) => response).catch(this.handleError);
  }
  // getDetailsfromIp(ipAddress): Observable<any> {
  //   return this.http.get("https://cors-anywhere.herokuapp.com/http://ip-api.com/json/" + ipAddress)
  //     .map((response: any) => response).catch(this.handleError);
  // }





  saveToken(body): Observable<any> {
    console.log("data", body);
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
          'authorization': `${localStorage.getItem('TOTOU_accessToken')}`
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authorization': `${localStorage.getItem('TOTOU_accessToken')}`
        })
      };
    }

    const url = this.saveTokenBaseUrl + this.saveTokenURL;
    return this.http.post(url, body, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getAccessDetails(data): Observable<any> {
    console.log("data", data);
    console.log("token", localStorage.getItem('TOTOU_accessToken'));
    const url = this.adminBaseUrl + this.getAccessDetailsURL + data;
    console.log("url", url);
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
          'authorization': `${localStorage.getItem('TOTOU_accessToken')}`
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authorization': `${localStorage.getItem('TOTOU_accessToken')}`
        })
      };
    }

    console.log("options", httpOptions);
    return this.http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  tokenexpiry(error) {
    console.log("inside tokenexpiry function");
    console.log("inside tokenexpiry function", error);
    if (error == 'Backend returned code 401') {
      localStorage.clear();
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/logout';
    }
  }

  // ===================================== Exception Handler ===========================================
  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      //A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      //The backend returned an unsuccessful response code.
      //The response body may contain clues as to what went wrong,
      errorMsg = `Backend returned code ${error.status}`;
    }
    //return an observable with a user-facing error message
    return throwError(errorMsg);
  }
}
