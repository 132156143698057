import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  accessDetails:any;
  userName:any;
  plntName:any;
  userRole:any;
  

  constructor(public router: Router) { }

  ngOnInit() { 
    if(localStorage.getItem('invaliduser') == "true"){
      this.router.navigateByUrl('/unauthorized')
    }
    this.accessDetails = JSON.parse(localStorage.getItem('TOTOU_accessDetail'));
    // console.log("access detail",this.accessDetails);
    // console.log(this.accessDetails.access_detail.response)
    // console.log(this.accessDetails.access_detail.response.length)
    if(this.accessDetails != null && this.accessDetails.access_detail.response.length != 0){
      let fName = this.accessDetails.access_detail.response[0].first_nm
      let lName = this.accessDetails.access_detail.response[0].last_nm
      this.userName = fName + " " + lName
      this.userRole = this.accessDetails.access_detail.response[0].user_role_nm
      if(this.userRole == "ADMIN"){
        this.plntName = ""
      }
      else{
        this.userRole = "PLANT ADMIN"
        this.plntName = ", " + this.accessDetails.access_detail.response[0].plnt_city
      }
      
    }
    
  }

  navigateToLogoutPopup(){
    $('#logoutPopUp').modal({ show: true, backdrop: 'static' });
  }

  logout(){
    localStorage.clear();
    sessionStorage.clear();
    $('#logoutPopUp').modal('hide')
    setTimeout(()=>{
      window.location.href='https://login.microsoftonline.com/common/oauth2/logout';
    }, 500)
  
  }

}
