
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TourLayoutComponent } from './view/tour-layout/tour-layout.component';
import { AdminLayoutComponent } from './view/admin-layout/admin-layout.component';
import { RoleGuard } from './auth-guard/role.guard';

const routes: Routes = [
 
  {
    path: 'id_token',
    redirectTo: '/user/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'indiana',
    redirectTo: '/indiana',
    pathMatch: 'full',
  },{
    path: 'kentucky',
    redirectTo: '/kentucky',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: '/user/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'mississippi',
    redirectTo: '/mississippi',
    pathMatch: 'full',
  },
  {
    path: 'texas',
    redirectTo: '/texas',
    pathMatch: 'full',
  },

  {
    path: 'main',
    loadChildren: () => import('./main-page/main-page.module').then(m => m.MainPageModule),
    // canActivate: [RoleGuard],
  },
  
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [RoleGuard],
  },
  // {
  //   path: 'group-size',
  //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  //   // canActivate: [RoleGuard],
  // },
  {
    path: 'indiana',
    loadChildren: () => import('./statis-page/statis-page.module').then(m => m.StaticModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'kentucky',
    loadChildren: () => import('./kentuky-static/kentuky-static.module').then(m => m.KentukystaticModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'mississippi',
    loadChildren: () => import('./mississippi/mississipi.component.module').then(m => m.MississipiModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'texas',
    loadChildren: () => import('./texas/texas.component.module').then(m => m.TexasModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'canada',
    loadChildren: () => import('./canada/canada.component.module').then(m => m.CanadaModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'tmmivr',
    loadChildren: () => import('./tmmivr/tmmivr.component.module').then(m => m.TmmivrModule),
   
    canActivate: [RoleGuard],
  },
  {
    path: 'tmmtxvr',
    loadChildren: () => import('./texas-virtual-tour/texas-virtual-tour.component.module').then(m => m.TexasVirtualTourModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'tmmmsvr',
    loadChildren: () => import('./mississippi-virtual-tour/mississippi-virtual.tour.component.module').then(m => m.MississipiVirtualTourModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'tmmkvr',
    loadChildren: () => import('./kentucky-virtual-tour/kentucky-virtual-tour.component.module').then(m => m.KentukyVirtualTourModule),
    canActivate: [RoleGuard],
  },    
  {
    path: 'tecvr',
    loadChildren: () => import('./plano-toyota-exp-center/plano-toyota-exp-center.component.module').then(m => m.planoToyotaVirtualTourModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'tmmalvr',
    loadChildren: () => import('./albama-virtual-tour/albama-virtual-tour.module').then(m => m.AlbamaVirtualTourModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'tmmwvvr',
    loadChildren: () => import('./west-virginia-virtual-tour/west-virginia-virtual-tour.module').then(m => m.WestVirginiaVirtualTourModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'tmmmovr',
    loadChildren: () => import('./missouri-virtual-tour/missouri-virtual-tour.module').then(m => m.MissouriVirtualTourModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'tmmtnvr',
    loadChildren: () => import('./tennessee-virtual-tour/tennessee-virtual-tour.module').then(m => m.TennesseeVirtualTourModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'tmmrdvr',
    loadChildren: () => import('./michigan-virtual-tour/michigan-virtual-tour.module').then(m => m.MichiganVirtualTourModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'educationhub',
    loadChildren: () => import('./education-hub/education-hub.component.module').then(m => m.educationHubModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'careers',
    loadChildren: () => import('./careers/careers.module').then(m => m.CareersModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'view-reservation',
    loadChildren: () => import('./reservation/reservation.module').then(m => m.ReservationModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./unauthorized/unauthorized.module').then(m => m.unauthorizedModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'cancelled',
    loadChildren: () => import('./cancelled-tour/cancelled-tour.module').then(m => m.CancelledModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'update-grp-info',
    loadChildren: () => import('./update-grp-info/update-grp-info.module').then(m => m.UpdategrpinfoModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'user',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./view/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
      canActivate: [RoleGuard],
    }]
  },
  {
    path: '',
    component: TourLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./view/tour-layout/tour-layout.module').then(m => m.TourLayoutModule),
      canActivate: [RoleGuard],
    }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [RoleGuard]
})

export class AppRoutingModule { }
