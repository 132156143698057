import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SideBarComponent } from './side-bar/side-bar.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { FormsModule } from '@angular/forms';
import { GroupSizeComponent } from '../group-size/group-size.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { AdminSidenavComponent } from '../admin-sidenav/admin-sidenav.component';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AppMaterialModule,
    HttpClientModule
  ],
  declarations: [
   SideBarComponent,
   ProgressBarComponent,
   AdminSidenavComponent
  ],
  exports: [
    SideBarComponent,
    ProgressBarComponent,
    AdminSidenavComponent
  ],
  providers:[GroupSizeComponent]
})
export class ComponentsModule { }
