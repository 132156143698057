import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { CommonService } from './shared-service/common.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  url = false;
  emailId: any;
  currentUser: any;
  TOTOUaccessDetailResponse: any;
  showspinner: boolean;
  workDayId: any;
  loginUser: boolean;
  invalidUser = false;
  toyTourScreenNameArray: any = []
  screenInfoIdArray: any = [];

  constructor(private readonly router: Router, private readonly appService: CommonService,
    private titleService: Title) {
    this.toyTourScreenNameArray = [
      { 'toy_tour_screen_info_id': 1, 'toy_tour_screen_info_name': 'Home' },
      { 'toy_tour_screen_info_id': 2, 'toy_tour_screen_info_name': 'Careers' },
      { 'toy_tour_screen_info_id': 3, 'toy_tour_screen_info_name': 'Education-Hub' },
      { 'toy_tour_screen_info_id': 4, 'toy_tour_screen_info_name': 'Indiana-EC' },
      { 'toy_tour_screen_info_id': 5, 'toy_tour_screen_info_name': 'Kentucky-EC' },
      { 'toy_tour_screen_info_id': 6, 'toy_tour_screen_info_name': 'Mississippi-EC' },
      { 'toy_tour_screen_info_id': 7, 'toy_tour_screen_info_name': 'Texas-EC' },
    ]
    localStorage.setItem('toyTourScreenName', JSON.stringify(this.toyTourScreenNameArray))
    console.log('(window.location.href outside router', window.location.href)
    let windowUrl = window.location.href
    console.log('window.location: ', window.location);
    // let url='http://localhost:4200/kentucky' 
    const kentuckyRegex = /kentucky/g;
    const indianaRegex = /indiana/g;
    const texasRegex = /texas/g;
    const mississippiRegex = /mississippi/g;
    const kentuckyFound = windowUrl.match(kentuckyRegex);
    const indianaFound = windowUrl.match(indianaRegex);
    const texasFound = windowUrl.match(texasRegex);
    const mississippiFound = windowUrl.match(mississippiRegex);
    console.log('kentuckyFound;', kentuckyFound);
    console.log('indianaFound;', indianaFound);
    console.log('texasFound;', texasFound);
    console.log('mississippiFound;', mississippiFound);
    let host = window.location.host;
    if (kentuckyFound) {
      this.router.navigate(['/kentucky'])
    } else if (indianaFound) {
      this.router.navigate(['/indiana'])
    } else if (texasFound) {
      this.router.navigate(['/texas'])
    } else if (mississippiFound) {
      this.router.navigate(['/mississippi'])
    } else if (host == 'www.tourtoyota.com' || host == 'tourtoyota.com' || host == 'tourtoyota.qa.toyota.com') {
      this.router.navigate(['/main']);
    }
    router.events.subscribe(async event => {
      if (event instanceof NavigationStart) {
        console.log('(window.location.href', window.location.href)
        if (!this.invalidUser) {

          var TOTOUAccessToken;
          console.log(window.location.href.indexOf('id_token'));

          if (window.location.href.indexOf('id_token') == -1) {
            this.loginUser = false
            this.showspinner = false
          }

          /// First Login either has id_token or error
          if (window.location.href.indexOf('id_token') != -1) {
            console.log(window.location.href.split('#')[1].split('id_token=')[1].split('&token')[0])
            TOTOUAccessToken = window.location.href.split('#')[1].split('id_token=')[1].split('&token')[0];
            localStorage.setItem('TOTOU_accessToken', TOTOUAccessToken);
            localStorage.setItem('TOTOU_loginSuccess', 'true');
            this.loginUser = true;
          }
          else if (window.location.href.indexOf('error') != -1) {
            localStorage.clear();
            console.log("inside err condition");
            this.invalidUser = true
            this.loginUser = false
            this.showspinner = false
            this.router.navigateByUrl('/unauthorized');
          }


          /// If already logged in the id_token will be undefined
          if (TOTOUAccessToken == undefined) {
            console.log('Login Success is set as null');
            localStorage.setItem('TOTOU_loginSuccess', "null")
          }

          /// If login for the first time then if current user is decoded for first time
          if (localStorage.getItem('TOTOU_loginSuccess') != "null" && !localStorage.getItem('TOTOU_currentUser')) {
            console.log('Logged In Successfully and Current User details not in storage');
            var decodedToken = jwt_decode(TOTOUAccessToken);
            localStorage.setItem('TOTOU_currentUser', JSON.stringify(decodedToken));
            this.currentUser = JSON.parse(localStorage.getItem('TOTOU_currentUser'));
            console.log(this.currentUser);
            this.workDayId = this.currentUser['workdayId']
            this.emailId = this.currentUser['upn'];
            console.log("this.emailId in constructor", this.emailId);
            localStorage.setItem('emailId', this.emailId)
            localStorage.setItem('workDayId', this.workDayId)
            const body = {
              "token": TOTOUAccessToken
            }
            this.appService.saveToken(body).subscribe((saveTokenResponse) => {
              if (saveTokenResponse) {
                console.log(`Token saved successfully with response: ${saveTokenResponse}`)
                this.appService.getAccessDetails(this.emailId).subscribe((response) => {
                  console.log("response", response)
                  this.TOTOUaccessDetailResponse = response
                  console.log("this.TOTOU_accessDetailResponse", this.TOTOUaccessDetailResponse);
                  if(this.TOTOUaccessDetailResponse.access_detail.response.length == 0 || 
                    this.TOTOUaccessDetailResponse.access_detail.response == undefined || 
                    this.TOTOUaccessDetailResponse.access_detail.response == null){
                    this.invalidUser = true;
                    localStorage.setItem('invaliduser',this.invalidUser + '')
                  }
                  localStorage.setItem('TOTOU_accessDetail', JSON.stringify(this.TOTOUaccessDetailResponse))
                  console.log("after setting TOTOU_accessDetail", JSON.parse(localStorage.getItem("TOTOU_accessDetail")));
                  this.showspinner = true;
                  console.log("showspinner", this.showspinner);
                },
                error => {
                    this.appService.tokenexpiry(error);
                })
              }
            },
            error => {
                this.appService.tokenexpiry(error);
            })
          }
          // If login for the first time then if current user is already in the storage        
          else if (localStorage.getItem('TOTOU_loginSuccess') != "null" && localStorage.getItem('TOTOU_currentUser')) {
            console.log('Current User details in localStorage');
          }
        }
      }

        if (event instanceof NavigationEnd) {
          let urlName = this.router.url.split('/')[1];
          console.log("urlname",urlName);
          if(urlName == 'login'){
            this.router.navigateByUrl('/user/dashboard')
          }
          if (urlName == "kentucky"){
            this.appService.setTitle('Schedule a Tour | Toyota Kentucky Experience Centre');
          }
          else if (urlName == "indiana"){
            this.appService.setTitle(' Schedule a Tour | Toyota Indiana Experience Centre');
          }
          else if (urlName == "mississippi"){
            this.appService.setTitle('Schedule a Tour | Toyota Mississippi Experience Centre');
          }
          else if (urlName == "texas"){
            this.appService.setTitle('Schedule a Tour | Toyota Texas Experience Centre');
          }
          else if (urlName == "tmmkvr"){
            this.appService.setTitle('Virtual Tour | Toyota Kentucky Experience Centre');
          }
          else if (urlName == "tmmivr"){
            this.appService.setTitle('Virtual Tour | Toyota Indiana Experience Centre');
          }
          else if (urlName == "tmmmsvr"){
            this.appService.setTitle('Virtual Tour | Toyota Mississippi Experience Centre');
          }
          else if (urlName == "tmmtxvr"){
            this.appService.setTitle('Virtual Tour | Toyota Texas Experience Centre');
          }
          else if (urlName == "tecvr"){
            this.appService.setTitle('Virtual Tour | Toyota North America Experience Centre');
          }
          else if (urlName == "tmmalvr"){
            this.appService.setTitle('Virtual Tour | Toyota Albama Experience Centre');
          }
          else if (urlName == "tmmwvvr"){
            this.appService.setTitle('Virtual Tour | Toyota West Virginia Experience Centre');
          }
          else if (urlName == "tmmmovr"){
            this.appService.setTitle('Virtual Tour | Toyota Missouri Experience Centre');
          }
          else if (urlName == "tmmtnvr"){
            this.appService.setTitle('Virtual Tour | Toyota Tennessee Experience Centre');
          }
          else if (urlName == "tmmrdvr"){
            this.appService.setTitle('Virtual Tour | Toyota Research & Development');
          }
          else if (urlName == "educationhub"){
            this.appService.setTitle('Toyota Education Hub');
          }
          else if (urlName == "careers"){
            this.appService.setTitle('Toyota Careers');
          }
          else if (urlName == "user"){
            this.appService.setTitle('Tours Admin');
          }
          else{
            this.appService.setTitle('Toyota Tours');
          }
        }

      


    });
  }

  

}
