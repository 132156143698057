import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../shared-service/common.service';

@Component({
  selector: 'app-tour-layout',
  templateUrl: './tour-layout.component.html',
  styleUrls: ['./tour-layout.component.scss']
})
export class TourLayoutComponent implements OnInit {

  smalldevices: Boolean = false;
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    window.innerWidth > 1024 ? this.smalldevices = false : this.smalldevices = true;
  }
  constructor(public router: Router, public appService: CommonService  ) {
    window.innerWidth > 1024 ? this.smalldevices = false : this.smalldevices = true;
  }

  ngOnInit() {
  }

}
