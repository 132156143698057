import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot) {
    var accessToken;
    console.log("role.guards")
    console.log("role.guards",window.location.href.includes('id_token'))
    console.log("invaliduser",localStorage.getItem('invaliduser'))
    console.log(window.location.href)
    if(window.location.href.includes("/user") || window.location.href.includes("/login"))
    {
      console.log("inside if cond in role gaurd");
    if (window.location.href.includes('id_token')) {
      accessToken = window.location.href.split('#')[1].split('id_token=')[1];
    }
    var sessionAccessToken = localStorage.getItem('TOTOU_accessToken');
    console.log("TOTOU_accessToken in role gaurd",localStorage.getItem('TOTOU_accessToken'));
    console.log("sessionAccessToken",sessionAccessToken);
    if (accessToken || sessionAccessToken) {
      this.router.navigateByUrl('/user/dashboard')
      return true;
    } else {
      console.log("no access token");
      window.location.href = environment.redirectUrl;
      return false;
    }
  }
  else{
    return true
  }
  }
}
