import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatIconModule,
  MatToolbarModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatMenuModule,
  MatCardModule,
  MatDividerModule,
  MatFormFieldModule,
  MatSelectModule,
  MatTooltipModule,
  MatInputModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatBadgeModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatStepperModule,
  
} from "@angular/material";

@NgModule({
  exports: [
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatBadgeModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatRippleModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatStepperModule
  ],
  imports: [
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatBadgeModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatRippleModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatNativeDateModule
  ]
  
})
export class AppMaterialModule { }

