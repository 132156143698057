import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../shared-service/common.service';
import { SideBarService } from './side-bar.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  providers: [CommonService]
})
export class SideBarComponent implements OnInit {
  sidelocationName: any = '';
  state: any = '';
  addrDesc: any = '';
  addrLine1: any = '';
  addrLine2: any = '';
  city: any = '';
  zipcode: any;
  phNo: string = '';
  termsLink: any = '';
  infoLink: any = '';
  visitors: any = 1;
  date: any;
  time: any;
  showDateTimeSection = false;
  uniquekey: any;
  show = false;
  plantDetails: any;
  plantId: any;
  showspinner = true
  plntId = '';

  constructor(private readonly appService: CommonService, public router: Router, private readonly sidebarService: SideBarService) {
    router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        if (router.url != '/group-size') {
          if (localStorage.getItem('groupSizeValue') != undefined) {
            this.visitors = localStorage.getItem('groupSizeValue');
          }
        }
        if (router.url != '/available-tour') {
          if (localStorage.getItem('dateValue') != undefined && localStorage.getItem('timeValue') != undefined) {
            this.showDateTimeSection = true;
            this.date = localStorage.getItem('dateValue');
            this.time = localStorage.getItem('timeValue');
          }
        }

      }
    });
  }

  async ngOnInit() {
    if (localStorage.getItem("viewReserFlag") == "view" && localStorage.getItem("uniqkeyFromReservation") != null) {
      let details = JSON.parse(localStorage.getItem('resvResponse'))
      console.log("details", details);
      if (details != null) {
        this.plantId = details[0].toy_tour_plnt_id
        this.visitors = details[0].grp_size
        let a = await this.getToken();
      if(a){
        this.appService.location = JSON.parse(localStorage.getItem('location'))
        console.log("this.appService.location in If", this.appService.location);
      }
      }
      if (this.appService.location != {} && this.appService.location != undefined) {
        console.log("inside sidber");
        if(this.appService.location.plnt_state == 'ON'){
          this.sidelocationName = this.appService.location.plnt_city + ', ' + this.appService.location.plnt_state + 'N3H 4R7'
        }
        else{
          this.sidelocationName = this.appService.location.plnt_city + ', ' + this.appService.location.plnt_state;
        }
       
        this.state = this.appService.location.plnt_state_nm;
        this.addrDesc = this.appService.location.add_desc;
        this.addrLine1 = this.appService.location.add_line_1;
        this.addrLine2 = '';
        this.city = this.appService.location.plnt_city;
        this.zipcode = (this.appService.location.plnt_zpcd == null)? '': this.appService.location.plnt_zpcd;
        this.phNo = this.appService.location.plnt_phno;
        this.plntId = this.appService.location.toy_tour_plnt_id;
        this.phNo = this.phNo.replace(/(\d{1})(\d{3})(\d{3})(\d)/, '$1-$2-$3-$4');
        console.log(this.phNo);
      }

    }
    else {
      setTimeout(() => {
        this.appService.location = JSON.parse(localStorage.getItem('location'));
        this.visitors = this.appService.groupSize;
        console.log("this.appService.location in else", this.appService.location);
        if (this.appService.location != {} && this.appService.location != undefined) {
          console.log("inside sidber");
          if(this.appService.location.plnt_state == 'ON'){
            this.sidelocationName = this.appService.location.plnt_city + ', ' + this.appService.location.plnt_state + 'N3H 4R7'
          }
          else{
            this.sidelocationName = this.appService.location.plnt_city + ', ' + this.appService.location.plnt_state;
          }
          this.state = this.appService.location.plnt_state_nm;
          this.addrDesc = this.appService.location.add_desc;
          this.addrLine1 = this.appService.location.add_line_1;
          this.addrLine2 = '';
          this.city = this.appService.location.plnt_city;
          this.zipcode = this.appService.location.plnt_zpcd;
          this.phNo = this.appService.location.plnt_phno;
          this.plntId = this.appService.location.toy_tour_plnt_id;
          this.phNo = this.phNo.replace(/(\d{1})(\d{3})(\d{3})(\d)/, '$1-$2-$3-$4');
          console.log(this.phNo);
        }
        this.showspinner= false;
      }, 5000);
     
     
     

    }
   




    

    if (localStorage.getItem('showedit') == "true") {
      this.show = true;
    }
    else if (localStorage.getItem('showedit') == "false") {
      this.show = false;
    }




  }

  navigatetogrpSize(){
    let details = JSON.parse(localStorage.getItem('location'))
    let queryParams = {
      plantId: details.toy_tour_plnt_id
    }
    this.router.navigate(['/group-size'],{queryParams:queryParams});
  }

  navigateToReservation() {
    this.uniquekey = localStorage.getItem('preUniquekey');
    this.router.navigateByUrl('/view-reservation/' + this.uniquekey)
  }

  getDataFromView() {
    this.sidebarService.gettourPlan().subscribe((response: any) => {
      console.log("plant information in sidebar component", response.Toyotatour_Plant.response);
      this.plantDetails = response.Toyotatour_Plant.response
      this.showspinner = false;
      for (let i = 0; i < this.plantDetails.length; i++) {
        if (this.plantId == this.plantDetails[i].toy_tour_plnt_id) {
          localStorage.setItem("location",JSON.stringify(this.plantDetails[i]))
        }
      }
  })
  }
  async getToken(){
    return new Promise(resolve =>{
      this.sidebarService.generateToken().subscribe(async (response:any) =>{
        console.log("token response",response.access_token);
        let sidebarToken = response.access_token
        localStorage.setItem('sidebarToken',sidebarToken)
        resolve(true)
          this.getDataFromView()
    })
  })

  }


}


