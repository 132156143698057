import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/shared-service/common.service';
import { ProgressBarService } from './progress-bar.service';
declare var $: any;

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./progress-bar.component.scss']
})

export class ProgressBarComponent implements OnInit {
  plnt_data: any = '';
  sidelocationName: any = '';
  state: any = '';
  addrDesc: any = '';
  addrLine1: any = '';
  addrLine2: any = '';
  city: any = '';
  zipcode: any;
  phNo: string = '';
  termsLink: any = '';
  infoLink: any = '';
  visitors: any = '';
  date: any;
  time: any;
  showDateTimeSection = false;
  showOverlay: boolean;
  statusStages: any = [1, 2, 3, 4, 5];
  componentcount = 9;

  subscription: any;
  value = 18;
  plantDetails: any;
  plantId: any;
  showspinner = true

  constructor(private readonly ref: ChangeDetectorRef, public router: Router,
    private readonly appService: CommonService, private readonly progressService: ProgressBarService) {
    
    this.subscription = this.router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        if (router.url != '/group-size') {
          if (localStorage.getItem('groupSizeValue') != undefined) {
            this.visitors = localStorage.getItem('groupSizeValue');
          }
        }
        if (router.url != '/available-tour') {
          if (localStorage.getItem('dateValue') != undefined && localStorage.getItem('timeValue') != undefined) {
            this.showDateTimeSection = true;
            this.date = localStorage.getItem('dateValue');
            this.time = localStorage.getItem('timeValue');
          }
        }
        let url = window.location.href;
        let items = url.split('/');
        
        let pathname = items[items.length - 1];
        if (pathname.includes('group-size')) {
          this.value = 16;
          this.completeClass(2);
        } else if (pathname == 'age-verification') {
          this.value = 29;
          this.completeClass(3);
        } else if (pathname == 'accessibility') {
          this.value = 41;
          this.completeClass(4);
        } else if (pathname == 'accessibility-warning') {
          this.value = 41;
          this.completeClass(4);
        } else if (pathname == 'available-tour') {
          this.value = 53;
          this.completeClass(5);
        } else if (pathname == 'my-info') {
          this.value = 65;
          this.completeClass(6);
        } else if (pathname == 'group-info') {
          this.value = 76;
          this.completeClass(7);
        } else if (pathname == 'policy') {
          this.value = 85.5;
          this.completeClass(8);
        } else if (pathname == 'confirmation') {
          this.value = 96;
          this.completeClass(9);
        } else {
          this.value = 100;
          this.completeClass(10);
        }
       
        
        this.ref.detectChanges();
      }
    });
  }

  completeClass(nos) {
    for (let i = 1; i < nos; i++) {
      $("#" + i).addClass('completedtick');
      $("#" + (i + 1)).addClass('inprogress');
    }
    for (let i = nos; i < 10; i++) {
      $("#" + i).removeClass('completedtick');
      $("#" + (i + 1)).removeClass('inprogress');
    }
  }


  async ngOnInit() {
    let url = window.location.href;
        let items = url.split('/');
        let pathname = items[items.length - 1];
        if (pathname.includes('group-size')) {
          this.value = 16;
          this.completeClass(2);
        } else if (pathname == 'age-verification') {
          this.value = 29;
          this.completeClass(3);
        } else if (pathname == 'accessibility') {
          this.value = 41;
          this.completeClass(4);
        } else if (pathname == 'accessibility-warning') {
          this.value = 41;
          this.completeClass(4);
        } else if (pathname == 'available-tour') {
          this.value = 53;
          this.completeClass(5);
        } else if (pathname == 'my-info') {
          this.value = 65;
          this.completeClass(6);
        } else if (pathname == 'group-info') {
          this.value = 76;
          this.completeClass(7);
        } else if (pathname == 'policy') {
          this.value = 85.5;
          this.completeClass(8);
        } else if (pathname == 'confirmation') {
          this.value = 96;
          this.completeClass(9);
        } else {
          this.value = 100;
          this.completeClass(10);
        }
        let a
        if (localStorage.getItem("viewReserFlag") == "view" && localStorage.getItem("uniqkeyFromReservation") != null) {
          let details = JSON.parse(localStorage.getItem('resvResponse'))
          console.log("details", details);
          if (details != null) {
            this.plantId = details[0].toy_tour_plnt_id
            this.visitors = details[0].grp_size
        a = await this.getToken();
          if (a) {
            this.appService.location = JSON.parse(localStorage.getItem('location'))
            console.log("this.appService.location in If", this.appService.location);
        }
          }
        }  
        else{
    this.appService.location = JSON.parse(localStorage.getItem('location'));
    this.visitors = this.appService.groupSize;
          console.log("this.appService.location in else",this.appService.location);
          this.showspinner = false;
        } 
    if (this.appService.location != {} && this.appService.location != undefined) {
      this.sidelocationName = this.appService.location.plnt_city + ',' + this.appService.location.plnt_state;
      this.state = this.appService.location.plnt_state_nm;
      this.addrDesc = this.appService.location.add_desc;
      this.addrLine1 = this.appService.location.add_line_1;
      this.addrLine2 = '';
      this.city = this.appService.location.plnt_city;
      this.zipcode = this.appService.location.plnt_zpcd;
      this.phNo = this.appService.location.plnt_phno;
      this.phNo = this.phNo.replace(/(\d{1})(\d{3})(\d{3})(\d)/, '$1-$2-$3-$4');
      console.log(this.phNo);
    }
  }

  ngOnDestroy() {
    this.ref.detach();
    this.subscription.unsubscribe();
  }


  getDataFromView() {
    this.progressService.gettourPlan().subscribe((response: any) => {
      console.log("plant information in progressbar component", response.Toyotatour_Plant.response);
        this.plantDetails = response.Toyotatour_Plant.response
        this.showspinner = false;
        for (let i = 0; i < this.plantDetails.length; i++) {
          if (this.plantId == this.plantDetails[i].toy_tour_plnt_id) {
            localStorage.setItem("location", JSON.stringify(this.plantDetails[i]))
        }
          }
    })
        }
  async getToken() {
    return new Promise(resolve => {
      this.progressService.generateToken().subscribe(async (response: any) => {
        console.log("token response", response.access_token);
        let progressbarToken = response.access_token
        localStorage.setItem('progressbarToken', progressbarToken)
        resolve(true)
        this.getDataFromView()
      })
    })
  }
}
