// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  redirectUrl: `https://login.microsoftonline.com/TOYOTA1.onmicrosoft.com/oauth2/authorize?client_id=e96e29d8-5156-452f-8799-ec9f7537cbbb&response_type=id_token&redirect_uri=https%3A%2F%2Ftour.toyota.com%2F%23%2Flogin%2F&scope=openid&state=12345&nonce=678910`,
  adminBaseUrl: "https://x5ljthwxye.execute-api.us-east-1.amazonaws.com/subprod/api/",
  createReservationBaseUrl: 'https://efqx8vg0o7.execute-api.us-east-1.amazonaws.com/subprod/api/',
  reportBaseUrl: 'https://8nhuwgfuu3.execute-api.us-east-1.amazonaws.com/subprod/api/',
  regisBaseUrl: 'https://9rtjtjlapj.execute-api.us-east-1.amazonaws.com/subprod/api/',
  cancelEmailBaseUrl: 'https://3t7n1jsr48.execute-api.us-east-1.amazonaws.com/subprod/api/',
  managePlantBaseUrl: 'https://aod776x2pi.execute-api.us-east-1.amazonaws.com/subprod/api/',
  saveTokenBaseUrl: 'https://ctg3f465s8.execute-api.us-east-1.amazonaws.com/subprod/api/',
  tourSaveTokenUrl :'https://ctg3f465s8.execute-api.us-east-1.amazonaws.com/subprod/api/JWT',
  exportDownloadUrl: 'https://d256amy274qyx5.cloudfront.net/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
